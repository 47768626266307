import React from 'react';
import '../styles/Footer.css';
import { Link } from 'react-router-dom';
import { Twitter, Facebook, Linkedin } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="main-footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>Quick Links</h3>
          <nav className="footer-nav">
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/tools">Tools</Link>
            <Link to="/contact">Contact</Link>
          </nav>
        </div>
        <div className="footer-section">
          <h3>Resources</h3>
          <nav className="footer-nav">
            <Link to="/faq">FAQ</Link>
            <Link to="/blog">Blog</Link>
            <Link to="/sitemap">Sitemap</Link>
          </nav>
        </div>
        <div className="footer-section">
          <h3>Legal</h3>
          <nav className="footer-nav">
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/terms">Terms of Service</Link>
          </nav>
        </div>
        <div className="footer-section">
          <h3>Newsletter</h3>
          <p>Stay updated with our latest tools and features</p>
          {/* Newsletter form coming soon
          <form className="newsletter-form">
            <input type="email" placeholder="Your email" required />
            <button type="submit">Subscribe</button>
          </form>
          */}
          <p>Newsletter signup coming soon</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Niche Site Analytics. All rights reserved.</p>
        <div className="social-links">
          <a href="https://twitter.com/nichesiteanalytics" target="_blank" rel="noopener noreferrer">
            <Twitter size={20} />
          </a>
          <a href="https://facebook.com/nichesiteanalytics" target="_blank" rel="noopener noreferrer">
            <Facebook size={20} />
          </a>
          <a href="https://linkedin.com/company/nichesiteanalytics" target="_blank" rel="noopener noreferrer">
            <Linkedin size={20} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;