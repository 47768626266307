import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import '../styles/Layout.css';

const GoogleAnalytics = () => {
  useEffect(() => {
    const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
    if (!googleAnalyticsId) {
      console.warn('Google Analytics ID is not set in environment variables');
      return;
    }

    // Load the Google Analytics script
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
    script.async = true;
    document.head.appendChild(script);

    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', googleAnalyticsId);

    // Clean up
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return null;
};

const Layout = ({ children }) => {
  return (
    <div className="layout">
      <GoogleAnalytics />
      <Header />
      <main className="main-content">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
